// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-address-jsx": () => import("./../../../src/pages/address.jsx" /* webpackChunkName: "component---src-pages-address-jsx" */),
  "component---src-pages-card-cards-json-number-jsx": () => import("./../../../src/pages/card/{cardsJson.Number}.jsx" /* webpackChunkName: "component---src-pages-card-cards-json-number-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

